import React from "react"
import CurrencyContext from "../../context/currency-context"
const Pricing = ({ pricing }) => {
  return (
    <CurrencyContext.Consumer>
      {(context) => {
        let currency
        switch (context.defaultValue) {
          case "USD":
            currency = pricing.USD
            break
          case "CAD":
            currency = pricing.CAD
            break
          case "MYR":
            currency = pricing.MYR
            break
          case "SGD":
            currency = pricing.SGD
            break
          case "INR":
            currency = pricing.INR
            break
          default:
            currency = pricing.INR
        }

        return (
          <>
            <div className="fs-32 fw-bolder">
              <span className="ps-0 pe-1">{currency.prefix}</span>
              {parseInt(Math.floor(currency.monthly))}
              <span className="fs-16">/mo</span>
            </div>
          </>
        )
      }}
    </CurrencyContext.Consumer>
  )
}

export default Pricing
