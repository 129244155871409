import React, { useState } from "react"
import CurrencyContext from "../../context/currency-context"
import getCurrencyId from "../../utils/get-currency-id"
import Dropdown from "react-dropdown"
import getOptionId from "./get-option-id"
const PricingSelect = ({ pricing, id }) => {
    const [data, setData] = useState("monthly")
    const optionID = getOptionId(data)
    return (
        <CurrencyContext.Consumer>
            {context => {
                let currency
                switch (context.defaultValue) {
                    case "USD":
                        currency = pricing.USD
                        break
                    case "CAD":
                        currency = pricing.CAD
                        break
                    case "MYR":
                        currency = pricing.MYR
                        break
                    case "SGD":
                        currency = pricing.SGD
                        break
                    case "INR":
                        currency = pricing.INR
                        break
                    default:
                        currency = pricing.INR
                }
                const currencyID = getCurrencyId(context)
                const options = [
                    {
                        value: "monthly",
                        label: `Monthly ${currency.suffix} ${currency.prefix}${currency.monthly}`,
                    },
                    {
                        value: "annually",
                        label: `1 Year at ${currency.suffix} ${currency.prefix}${currency.annually}`,
                    },
                    {
                        value: "biennially",
                        label: `2 Years at ${currency.suffix} ${currency.prefix}${currency.biennially}`,
                    },
                    {
                        value: "triennially",
                        label: `3 Years at ${currency.suffix} ${currency.prefix}${currency.triennially}`,
                    },
                ]
                return (
                    <>
                        <div className="input-group mb-3">
                            <Dropdown
                                options={options}
                                onChange={event => setData(event.value)}
                                value={options[optionID]}
                                placeholder="Select an option"
                            />
                        </div>
                        <a
                            className="btn btn-primary"
                            href={`https://my.motherhost.com/cart.php?currency=${currencyID}&a=add&pid=${id}&billingcycle=${data}`}
                        >Click to Deploy »</a>
                    </>
                )
            }}
        </CurrencyContext.Consumer>
    )
}

export default PricingSelect