import React from "react"
import CurrencyContext from "../../../../context/currency-context"
import PricingCloudSelect from "../../../ui/PricingCloudSelect"
import Pricing from "../../../ui/pricing"
import { useStaticQuery, graphql } from "gatsby"
const UnManagedMemory = () => {
  const allData = useStaticQuery(graphql`
    query {
      allProductsJson(filter: { gid: { eq: 46 } }) {
        edges {
          node {
            pid
            name
            description
            pricing {
              INR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              MYR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              SGD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              USD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              CAD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
            }
            configoptions {
              configoption {
                options {
                  option {
                    pricing {
                      INR {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      MYR {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      SGD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      USD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      CAD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = allData.allProductsJson.edges

  //const allData = UseHttpRequest(config)
  if (data.length <= 0) {
    return <></>
  }
  return (
    <CurrencyContext.Consumer>
      {(context) => {
        return (
          <>
            <div className="row">
              {data.map((item, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-3">
                  <div className="text-center py-3 px-3 mb-4 card cloud-plan position-relative">
                    <div className="storage">{item.node.name}</div>
                    <div className="cloud-price">
                      <Pricing pricing={item.node.pricing} id={item.node.pid} />
                    </div>
                    <hr />
                    <ul className="m-0 p-0 text-center feature">
                      <li
                        className="py-2"
                        dangerouslySetInnerHTML={{
                          __html: item.node.description,
                        }}
                      />
                    </ul>
                    <PricingCloudSelect
                      pricing={item.node.pricing}
                      id={item.node.pid}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      }}
    </CurrencyContext.Consumer>
  )
}

export default UnManagedMemory
