import React from "react"
import HeaderCloud from "./HeaderCloud"
import PlansUnManagedServer from "./PlansUnManagedServer"
import CloudServerFeatures from "./CloudServerFeatures"
import FaqCloudServer from "./FaqCloudServer"
import Seo from "../../../components/seo"

const CloudServerPage = () => {
  return (
    <div className="content bg">
      <Seo
        title="Managed VPS or Cloud Server"
        desc="Motherepos offers Cloud Server, Cloud Disaster Recovery Services, Database Server &amp; Bigdata and many other cloud based services."
        keywords="Best cloud Server, point of sale database server"
      />

      <HeaderCloud />
      <section className="pricing cloud-server-plans">
        <div className="container-xl">
          <h2 className="component-title d-inline-block py-4">Cloud Server</h2>
          <PlansUnManagedServer />
        </div>
      </section>
      <CloudServerFeatures />
      <FaqCloudServer />
    </div>
  )
}

export default CloudServerPage
