
const getCurrencyId = (currency) => {
    let currencyId
    switch (currency.defaultValue) {
        case "USD":
            currencyId = 2
            break
        case "CAD":
            currencyId = 5
            break
        case "MYR":
            currencyId = 7
            break
        case "SGD":
            currencyId = 3
            break
        case "INR":
            currencyId = 1
            break
        default:
            currencyId = 1
    }
    return currencyId
};


export default getCurrencyId;