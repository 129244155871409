const getOptionId = data => {
  let optionID
  switch (data) {
    case "monthly":
      optionID = 0
      break
    case "annually":
      optionID = 1
      break
    case "biennially":
      optionID = 2
      break
    case "triennially":
      optionID = 3
      break
    default:
      optionID = 0
  }
  return optionID
}

export default getOptionId
